import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from "react-i18next";


interface NuxSearchFieldProps {
    onSearch: ((searchText: string) => void)
}

const NuxSearchField = ({onSearch}: NuxSearchFieldProps) => {
    const {t} = useTranslation()
    return (
        <Paper
            component="form"
            variant="outlined"
            onSubmit={event => event.preventDefault() }
            sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', borderRadius: '25px'}}
        >
            <IconButton type="button" sx={{p: '10px'}} aria-label="search">
                <SearchIcon/>
            </IconButton>
            <InputBase
                sx={{ml: 1, flex: 1}}
                placeholder={t('searchFor',  { ns: 'nux' })}
                inputProps={{'aria-label': t('searchFor',  { ns: 'nux' })}}
                onChange={event => onSearch(event.currentTarget.value) }
            />
        </Paper>
    );
}

export default NuxSearchField
