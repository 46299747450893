import React, {createContext, FC, useContext} from "react";

import {
    CollectorSession,
    CollectorSessionResponse,
    CollectorStatusResponse,
    ErrorResponse,
    InitiateCollectorResponse,
    InsightsResponse
} from "./AccountInsightsTypes";
import {useAuth} from "../nux/niam/auth";
import config from "../config/config";
import {useTranslation} from "react-i18next";

export interface AccountInsightClient {
    allAccountInsightSessions(
        searchValue: string,
        limit: number,
        offset: number,
        status: string | null,
        archived: boolean | null,
        createdBy: string | null
    ): Promise<CollectorSessionResponse>
    accountInsightInitiate(personId: string, name: string, email: string, shouldSendLinkByMail: boolean): Promise<InitiateCollectorResponse>,
    getAccountInsight(id: string): Promise<CollectorSession>
    getVisualisationLink(id: string): Promise<string>
    restart(id: string): Promise<CollectorSession>
    report(id: string, language: string): Promise<Blob>
    removeSession(id: string): Promise<void>
    archiveSession(id: string): Promise<CollectorStatusResponse>
    unarchiveSession(id: string): Promise<CollectorStatusResponse>
}

export class AccountInsightApiError extends Error {
    apiErrorCode: string;
    params: { [key: string]: string } ;

    constructor(message: string, apiErrorCode: string, params: { [p: string]: string }) {
        super(message);
        this.apiErrorCode = apiErrorCode;
        this.params = params;
    }
}


const defaultAccountInsightClientContext: AccountInsightClient = {
    allAccountInsightSessions(searchValue: string): Promise<CollectorSessionResponse> {
        return Promise.reject("Un-Initialized!")
    },
    accountInsightInitiate(personId: string, name: string, email: string, shouldSendLinkByMail: boolean): Promise<InitiateCollectorResponse> {
        return Promise.reject("Un-Initialized!")
    },
    getAccountInsight(id: string): Promise<CollectorSession> {
        return Promise.reject("Un-Initialized!")
    },
    getVisualisationLink(id: string): Promise<string> {
        return Promise.reject("Un-Initialized!")
    },
    restart(id: string): Promise<CollectorSession> {
        return Promise.reject("Un-Initialized!")
    },
    report(id: string, language: string): Promise<Blob> {
        return Promise.reject("Un-Initialized!")
    },
    removeSession(id: string): Promise<void> {
        return Promise.reject("Un-Initialized!")
    },
    archiveSession(id: string): Promise<CollectorStatusResponse> {
        return Promise.reject("Un-Initialized!")
    },
    unarchiveSession(id: string): Promise<CollectorStatusResponse> {
        return Promise.reject("Un-Initialized!")
    }
};

const AccountInsightClientContext = createContext<AccountInsightClient>(defaultAccountInsightClientContext);


interface AccountInsightClientProviderProps {
    children: React.ReactNode
}

export const AccountInsightClientProvider: FC<AccountInsightClientProviderProps> =
    ({children}: AccountInsightClientProviderProps) => {

        const accountInsightClient = useProvideAccountInsightClient()

        return (
            <AccountInsightClientContext.Provider
                value={accountInsightClient}>{children}</AccountInsightClientContext.Provider>
        );
    };

export const useAccountInsightClient = () => useContext(AccountInsightClientContext);

interface ReturnPromiseOfResponseType {
    (): Promise<Response>;
}
const useProvideAccountInsightClient = (): AccountInsightClient => {
    const {accessToken, authenticated, resetLogin, login} = useAuth()
    const {t} = useTranslation()

    const getDefaultHeaders = () => {
        return {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    };

    const getDefaultPdfHeaders = () => {
        return {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
        };
    };

    const get = (action: string) => {
        return fetch(action, {
            headers: getDefaultHeaders(),
            method: 'GET'});
    }

    const getPdf = (action: string) => {
        return fetch(action, {
            headers: getDefaultPdfHeaders(),
            method: 'GET'});
    }

    const deleteRequest = (action: string) => {
        return fetch(action, {
            headers: getDefaultHeaders(),
            method: 'DELETE'});
    }

    const post = (action: string, body: any) => {
        return fetch(action, {
            headers: getDefaultHeaders(),
            method: 'POST',
            body});
    }

    const retryAction = async (action: ReturnPromiseOfResponseType) => {
        let retryCount =  2
        let retry =  false
        let response = null
        do {
            try {
                response = await action()
            } catch (e) {
                console.error(e)
                throw new Error('Internal error communicating with backend. Retry later!');
            }

            if (response.status === 200) {
                return await response.json();
            }
            retryCount--
            if (response.status === 401) {
                resetLogin()
                await login()
                retry = true;
            }
        } while (retry && retryCount > 0)
        if (response.status === 404 || response.status === 400) {
            let error = await response.json() as ErrorResponse;
            console.error("Received Error from API ",error)
            if (error.params)
                throw new AccountInsightApiError(t(error.apiErrorCode, error.params), error.apiErrorCode, error.params);
            else
                throw new AccountInsightApiError(t(error.apiErrorCode), error.apiErrorCode, {});
        }
        let error = await response.text();
        throw new Error('error occur when calling consumer-accountinsight status='+response.status+" "+response.statusText +" "+ error);
    }

    const retryActionText = async (action: ReturnPromiseOfResponseType) => {
        let retryCount =  2
        let retry =  false
        let response = null
        do {
            try {
                response = await action()
            } catch (e) {
                console.error(e)
                throw new Error('Internal error communicating with backend. Retry later!');
            }

            if (response.status === 200) {
                return await response.text();
            }
            retryCount--
            if (response.status === 401) {
                resetLogin()
                await login()
                retry = true;
            }
        } while (retry && retryCount > 0)
        if (response.status === 404 || response.status === 400) {
            let error = await response.json() as ErrorResponse;
            console.error("Received Error from API ",error)
            if (error.params)
                throw new AccountInsightApiError(t(error.apiErrorCode, error.params), error.apiErrorCode, error.params);
            else
                throw new AccountInsightApiError(t(error.apiErrorCode), error.apiErrorCode, {});
        }
        let error = await response.text();
        throw new Error('error occur when calling consumer-accountinsight status='+response.status+" "+response.statusText +" "+ error);
    }

    const retryActionBlob = async (action: ReturnPromiseOfResponseType) => {
        let retryCount =  2
        let retry =  false
        let response = null
        do {
            try {
                response = await action()
            } catch (e) {
                console.error(e)
                throw new Error('Internal error communicating with backend. Retry later!');
            }

            if (response.status === 200) {
                return await response.blob();
            }
            retryCount--
            if (response.status === 401) {
                resetLogin()
                await login()
                retry = true;
            }
        } while (retry && retryCount > 0)
        if (response.status === 404 || response.status === 400) {
            let error = await response.json() as ErrorResponse;
            console.error("Received Error from API ",error)
            if (error.params)
                throw new AccountInsightApiError(t(error.apiErrorCode, error.params), error.apiErrorCode, error.params);
            else
                throw new AccountInsightApiError(t(error.apiErrorCode), error.apiErrorCode, {});
        }
        let error = await response.text();
        throw new Error('error occur when calling consumer-accountinsight status='+response.status+" "+response.statusText +" "+ error);
    }

    const allAccountInsightSessions = async (
        searchValue: string,
        limit: number,
        offset: number,
        status: string | null,
        archived: boolean | null,
        createdBy: string | null
    ) => {
        if (!accessToken)
            await login()

        return await retryAction(() =>
            get(`${config.TARGET_URL}?searchValue=${searchValue}&limit=${limit}&offset=${offset}${status ? `&status=${status}` : ''}${archived ? `&archived=${archived}` : ''}${createdBy ? `&createdBy=${createdBy}` : ''}`)
        ) as CollectorSessionResponse
    };

    const accountInsightInitiate = async (personId: string, name: string, email: string, shouldSendLinkByMail: boolean) => {
        if (!accessToken)
            await login()

        return await retryAction(() =>
            post(`${config.TARGET_URL}`, JSON.stringify({
                personId,
                name,
                email,
                shouldSendLinkByMail
            }))
        ) as InitiateCollectorResponse;
    };

    const getAccountInsight = async (id: string) => {
        if (!accessToken)
            await login()

        return await retryAction(() =>
            get(`${config.TARGET_URL}/${id}?excludeVisualisationLink=true`)
        ) as CollectorSession
    };

    const getVisualisationLink = async (id: string) => {
        if (!accessToken)
            await login()
        return await retryActionText(() =>
            get(`${config.TARGET_URL}/${id}/visualisationLink`)
        ) as string
    };

    const restart = async (id: string) => {
        if (!accessToken)
            await login()

        return await retryAction(() =>
            post(`${config.TARGET_URL}/restart`, JSON.stringify({
                "collectionSessionId":id
            }))
        ) as CollectorSession
    };
    //report(id: string): Promise<Blob>
    const report = async (id: string, language: string) => {
        if (!accessToken)
            await login()

        return await retryActionBlob(() =>
            getPdf(`${config.TARGET_URL}/${id}/report?lang=${language}`)
        )
    };

    const accountInsightStatus = async (id: string) => {
        if (!accessToken)
            await login()

        return await retryAction(() =>
            get(`${config.TARGET_URL}/${id}/status`)
        ) as CollectorStatusResponse
    };

    const accountInsightInsights = async (id: string) => {
        if (!accessToken)
            await login()

        return await retryAction(() =>
            get(`${config.TARGET_URL}/${id}/insights`)
        ) as InsightsResponse
    };

    const removeSession = async (id: string) => {
        if (!accessToken)
            await login()

        await deleteRequest(`${config.TARGET_URL}/${id}`)
    };

    const archiveSession = async (id: string) => {
        if (!accessToken)
            await login()

        return await retryAction(() =>
            post(`${config.TARGET_URL}/archive`, JSON.stringify({
                "collectionSessionId":id
            }))
        ) as CollectorStatusResponse
    }

    const unarchiveSession = async (id: string) => {
        if (!accessToken)
            await login()

        return await retryAction(() =>
            post(`${config.TARGET_URL}/unarchive`, JSON.stringify({
                "collectionSessionId":id
            }))
        ) as CollectorStatusResponse
    }

    return {
        allAccountInsightSessions,
        accountInsightInitiate,
        getAccountInsight,
        getVisualisationLink,
        restart,
        report,
        removeSession,
        archiveSession,
        unarchiveSession
    }
};
