import React, {ReactNode} from 'react';
import {I18nextProvider} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import englishTranslation from "./en/translation.json";
import swedishTranslation from "./sv/translation.json";
import finnishTranslation from "./fi/translation.json";

export interface NuxTranslationProviderProps {
    children: ReactNode
    languageResources?: I18nResource
}

export interface I18nResource{
    [lang: string]: {
        [namespace: string]: {
            [key: string]: string | any;
        };
    };
}

let nuxResources : I18nResource = {
    en: {
        nux: englishTranslation,
    },
    sv: {
        nux: swedishTranslation,
    },
    fi: {
        nux: finnishTranslation,
    },
}

const NuxTranslationProvider = ({languageResources, children}: NuxTranslationProviderProps) => {

    let initProps = {
        //lng: 'en', // if you're using a language detector, do not define the lng option
        debug: true,
        resources: mergeResource(nuxResources, languageResources),
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        fallbackLng: 'en',
        defaultNS: 'translation'
    }

    const i18n = i18next.createInstance();
    i18n
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        .init(initProps);

    return (
        <I18nextProvider i18n={i18n}>
            {children}
        </I18nextProvider>
    )

}

function mergeResource(resources1 : I18nResource, resources2? : I18nResource): I18nResource  {
    if (resources2) {
        const merged: I18nResource = {...resources1};

        for (const key in resources2) {
            if (resources2.hasOwnProperty(key)) {
                if (resources1.hasOwnProperty(key) && typeof resources1[key] === 'object' && typeof resources2[key] === 'object') {
                    merged[key] = mergeResource(resources1[key], resources2[key]);
                } else {
                    merged[key] = resources2[key];
                }
            }
        }

        return merged;
    }
    return resources1
}

export default NuxTranslationProvider
