import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import NuxButton from '../../../nux/components/buttons/NuxButton';
import ProgressSuccess from './ProgressSuccess';

interface CreatingNewAccountInsightSessionDialogProps {
  consentUrl: string | null;
  message: string;
  show: boolean;

  close(): void;

  onCopyConsentUrlToClipboard: (clientRedirectUrl: string) => void;
}

export const CreatingNewAccountInsightSessionDialog = ({
                                                         consentUrl,
                                                         message,
                                                         show,
                                                         close,
                                                         onCopyConsentUrlToClipboard,
                                                       }: CreatingNewAccountInsightSessionDialogProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (consentUrl)
      onCopyConsentUrlToClipboard(consentUrl)
  }, [consentUrl]);


  return (
    <Dialog onClose={close} open={show}>
      <DialogTitle>{t('creatingSession')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!consentUrl
            ? t('inquryUnderCreation')
            : t('createdYouMayNowCopyTheUrl')
          }
        </DialogContentText>
        {message && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {message}
          </Alert>
        )}
        <Stack direction="row" spacing={1} alignItems="center">
          <ProgressSuccess
            success={!!consentUrl}
            loading={!consentUrl}
          />
          <NuxButton
            data-testid="do-create-insight-session-request"
            disabled={!consentUrl}
            color="success"
            onClick={() => onCopyConsentUrlToClipboard(consentUrl ? consentUrl : '')}
            variant="contained">
            {t('copyConsentToClipboardButtonCaption')}
          </NuxButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <NuxButton
          data-testid="cancel-create-insight-session-request"
          onClick={close}
          color="secondary"
          variant="outlined">
          {t('dialogClose')}
        </NuxButton>
      </DialogActions>

    </Dialog>
  );
};
