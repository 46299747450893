import React, { ReactElement, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from '../error/ErrorPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './niam/auth';
import NuxTranslationProvider from './i18n/NuxTranslationProvider';
import { theme } from './styles/Theme';
import { ThemeProvider } from '@mui/material/styles';
import { NiamService } from './niam/NiamAuthTypes';
import { Config } from './index';
import { ConfirmDialogProvider } from './components/dialogs/NuxDialog';


export interface NuxAppProps {
  layout(children: ReactNode): ReactNode;

  config: Config;
  startPage: ReactNode;
  routes: ReactElement<typeof Route>[];
  languageResources?: { [lng: string]: any };
  permissions: string[];
}

const NuxApp = ({ layout, startPage, routes, config, languageResources, permissions }: NuxAppProps) => {

  return (
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
        <NuxTranslationProvider languageResources={languageResources}>
          <ConfirmDialogProvider>
            <BrowserRouter>
              <AuthProvider
                authorizeUrl={`${config.NIAM_URL}/authorize`}
                deAuthorizeUrl={`${config.NIAM_URL}/connect/endSession`}
                tokenUrl={`${config.NIAM_URL_ACCESSTOKEN}/access_token`}
                userInfoUrl={`${config.NIAM_URL}/userinfo`}
                clientId="accountinsight"
                scopes={['openid', 'profile', 'email', 'permissions']}
                redirectUrl={`${config.NIAM_REDIRECT_URL}`}
                service={NiamService.AADLogin}
                permissions={permissions}>
                {layout(<Routes>
                  <Route key="1" path="/" element={startPage} />
                  {routes}
                </Routes>)}
              </AuthProvider>
            </BrowserRouter>
          </ConfirmDialogProvider>
        </NuxTranslationProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};

export default NuxApp;
